<template>
    <div>
        <!-- <h1>Noticias</h1> -->

        <li class="menu-item">
            <router-link
                to="/noticias/municipio"
                v-slot="{ href, route, navigate, isActive, isExactActive }"
                custom
            >
                <a
                    :class="[
                        'menu-link',
                        isActive && 'router-link-active',
                        isExactActive && 'router-link-exact-active',
                    ]"
                    :href="href"
                    @click="navigate"
                    >{{ route.name }}</a
                >

                <svg
                    :class="[
                        'item-decoration',
                        isActive && 'router-link-active',
                        isExactActive && 'router-link-exact-active',
                    ]"
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="15"
                    viewBox="0 0 15 20"
                >
                    <path
                        d="M7.5,3.744a3,3,0,0,1,4.992,0l4.394,6.592A3,3,0,0,1,14.394,15H5.606a3,3,0,0,1-2.5-4.664Z"
                        transform="translate(15) rotate(90)"
                    />
                </svg>
            </router-link>
        </li>
    </div>
</template>

<style lang="scss" scoped>
div {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
}

li {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row-reverse;
    padding-right: 2rem;
}

$inactive-color: #888;
.item-decoration {
    fill: $inactive-color;
    transition: 300ms;
}
.menu-link:hover + .item-decoration {
    fill: #dbc056;
    transform: translate(-3px);
}

a {
    font-weight: bold;
    color: $inactive-color;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    transition: 300ms;
    font-size: 1rem;
    // text-transform: uppercase;

    // text-shadow: rgba($color: #fff, $alpha: 1) 0px 0px 12px;
}

.router-link-exact-active {
    color: #dbc056;
    fill: #dbc056;
}

.router-link-active {
    color: #dbc056;
    fill: #dbc056;
}
</style>
